import { FC } from 'react';
import { isMonday, isToday, nextMonday } from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import './Welcome.scss';

import logo from '../assets/img/logo.svg';
import facetimeLogo from '../assets/img/facetime.svg';
import beyondLogo from '../assets/img/beyond.svg';
import { formatInTimeZone } from 'date-fns-tz';

const FACETIME_URL =
  'https://facetime.apple.com/join#v=1&p=rSeDRRIMEe+Gui7CZcSzSg&k=WkthGHRz8v5pyBCJMNbZfQdjSP9hRRXhweLLjN3NNSM';
const FACETIME_DEEP_LINK = `facetime-open-link://?link=${encodeURIComponent(
  FACETIME_URL,
)}`;

export const Welcome: FC = () => {
  const now = new UTCDate();
  now.setHours(20);
  now.setMinutes(0);
  now.setSeconds(0);
  const nextCall = isMonday(now) ? now : nextMonday(now);

  return (
    <div className="welcome">
      <img src={logo} alt="Spatial Social Club" className="welcome__logo" />
      <p className="welcome__intro">
        A weekly meetup to bring the Vision Pro community closer together, chat
        about VR/XR, hang out, meet new people, and experiment with new ways to
        use Spatial Personas.
      </p>
      <p className="welcome__intro">
        The next meetup is{' '}
        {isToday(nextCall)
          ? 'today'
          : `on ${formatInTimeZone(nextCall, 'Europe/Berlin', 'LLLL do')}`}{' '}
        at {formatInTimeZone(nextCall, 'Europe/Berlin', 'HH:mm zzz')}. See you
        there!
      </p>
      <a href={FACETIME_DEEP_LINK} className="welcome__link">
        <img src={facetimeLogo} alt="" className="welcome__facetime" />
        Join FaceTime
      </a>
      <p className="welcome__footer">
        Brought to you by your spatial friends at{' '}
        <a href="https://beyond.digital" className="welcome__beyond">
          <img src={beyondLogo} alt="beyond Digital" />
        </a>{' '}
        ✌️
      </p>
    </div>
  );
};
